import React, { Component } from 'react'
import '../App.css'
import logo from '../imgs/logo.svg'


export default class Header extends Component {
  render() {

    return (
        <div className="tp-top-bar-fixed"><div className="top-bar"><img src={logo} className="logo" /><span>enabled.io</span></div></div>
    )
  }
}

export class shrinkRing {
    constructor(start, duration, open) {
        this.start = start
        this.duration = duration
        this.open = open
        this.turn = 600
        this.mod = 300
        this.expand = 0
        this.params = []
    }

    do(position) {

        if (position> this.start && position < (this.start + this.duration)) {

            const speed = (this.mod) / this.duration
            this.turn = this.mod;
            const fact = (((position - this.start) % this.mod) * speed);
            const scroll = (position - this.start) % this.mod;


            this.open ? this.expand = fact : this.expand = this.mod - fact


            this.params["ringStatusDashArray"] = (this.turn - this.expand + 28) + " " + this.expand
            this.params["ringStatusDashOffset"] = (-this.expand / 2).toString();
            //return this.params
        }

    }


}

export class splitTag {
    constructor(start, duration, shiftStart, shiftEnd) {
        this.start = start
        this.duration = duration
        this.shiftStart = shiftStart
        this.shiftEnd = shiftEnd
        const limit = 500.0;

        let up = 1;

        this.startPos = limit * shiftStart;
        this.endPos = limit * shiftEnd;
        this.step = (this.startPos - this.endPos)/duration

        this.params = []


    }

    do(position) {
        if(position>this.start && position<this.start + this.duration) {
            this.step = (this.endPos - this.startPos) * (position-this.start)/this.duration
        this.params["tagShift"] =   (this.startPos + this.step)
            console.log(this.step + "Step" + this.startPos);
        }

        /*else {
            if (position < this.start) this.params["tagShift"] = 300 * this.shiftStart
            if (position > this.start + this.duration) this.params["tagShift"] = 300 * this.shiftEnd
        }*/
    }

}

export class rotateRing {
    constructor(start, duration, deg) {
        this.start = start
        this.duration = duration
        this.deg = deg
        this.params = []

    }

    do(position) {
        if(position>this.start && position<this.start + this.duration && position !== 0) {
            const speed = (360 / this.duration) * this.deg
            this.params["ringStatusRotate"] = (position - this.start) * speed
        }
    }


}

import React from 'react';
import Main from "./components/Main"
import Header from "./components/Header"
import {
    BrowserRouter,
    Route,
    Switch,
    Link, Routes
} from 'react-router-dom'
import './App.css';


function App() {
    return (

        <div className="App">
            <BrowserRouter>
                <div>
                    <Header/>
                    <Routes>
                        <Route path="/" element={<Main/>}/>
                    </Routes>
                </div>
            </BrowserRouter>
        </div>

    );
}

export default App;

import React, {Component, useEffect} from 'react'
import logo_anim from '../imgs/logo_anim.svg'
import '../App.css'
import gsap from 'gsap'
import {Parallax} from 'react-scroll-parallax';
import ringRight from '../imgs/ring_right.svg'
import test from '../imgs/logo.svg';
import {Animate} from "react-move";
import {interpolate, interpolateTransformSvg} from "d3-interpolate";
import {tween, Tweenable} from 'shifty'
import * as anim from './logo_actions/anims'

const mod = 300;
var expand = 0;
var ring, rl, rr, rrp, rlp, tu, td, pos, tux, tdx, tuy, tdx;
var tagShift = 0.2
var tagShiftLastPos = 0.0
var up = -1
var lastPos = 0.0
var shiftEnd, shiftUpEnd = false;
var ringStatusDashArray = ''
var ringStatusDashOffset = '0'
var ringStatusRotate = 0
var shrinkEnd = false
var rows = [];
var tweenObj = null;
var f;
var logo;
var body;
var text;
var textl;
var timeline = [];

for (var i = 0; i < 12; i++) {
    rows.push(i != 11 ? <div className="tp-nice-12-first" id={"col-nr-" + i}>
        <svg width="100%" height="100%" x="0px"
             y="0px"
             viewBox="0 0 100 100" cx="0" cy="0" preserveAspectRatio="none">
            <line x1="100" y1="0" x2="100" y2="100" stroke="#fff" stroke-dasharray="6 2" stroke-dashoffset="0"
                  stroke-width="1"></line>
        </svg>
    </div> : <div className="tp-nice-12-first"></div>)


}


function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}


export default class Main extends Component {

    app = this;
    timeline = []
    state = {
        show: false,
        posState: 0.0,
        posStart: 0.0,
    };

    constructor(props) {
        super(props);
        //timeline.push(new anim.shrinkRing(100, 100, true))
        //timeline.push(new anim.shrinkRing(300, 100, false))
        timeline.push(new anim.shrinkRing(0, 10.0, true))
        timeline.push(new anim.splitTag(0, 10.0, 0.045, 0.045))
        timeline.push(new anim.rotateRing(0, 10.0,0.0))

        timeline.push(new anim.shrinkRing(70, 100.0, true))
        timeline.push(new anim.splitTag(130, 200.0, 0.05, 0.5))
        timeline.push(new anim.rotateRing(240, 100.0, 0.5))
        timeline.push(new anim.shrinkRing(430, 300.0, false))
        timeline.push(new anim.rotateRing(340, 160.0, -0.5))
        timeline.push(new anim.splitTag(330, 60, 0.5, 0.045))
        timeline.push(new anim.rotateRing(500, 350.0, 1))
        timeline.push(new anim.splitTag(600, 100, 0.045, -0.001))
        timeline.push(new anim.splitTag(700, 100, -0.001, 0.045))
        //timeline.push(new anim.shrinkRing(500, 200.0, true))


    }

    componentDidUpdate() {


    }

    componentDidMount() {
        f = 0.6;
        logo = document.getElementById('logo-id')
        body = document.getElementsByTagName('body')[0]
        text = document.getElementById('tp-story-text')
        textl = document.getElementById('tp-story-text-left')
        ring = document.getElementById("ring")
        rl = document.getElementById("ring-right")
        rr = document.getElementById("ring-left")
        rrp = document.getElementById("ring-right-path")
        rlp = document.getElementById("ring-left-path")
        tu = document.getElementById("tag-up")
        td = document.getElementById("tag-down")
        this.init()
    }


    init() {

        this.animate()
        //window.setInterval(function (){this.animate();}.bind(this), 200,);
        window.setInterval(function () {
            if(window.scrollY===0){
                tu.setAttribute("transform", "translate(30,-30)")
                td.setAttribute("transform", "translate(-30,30)")


                rrp.setAttribute("stroke-dasharray", "3000 0")
                rrp.setAttribute("stroke-dashoffset", "0")
                rlp.setAttribute("stroke-dasharray", "3000 0")
                rlp.setAttribute("stroke-dashoffset", "0")
                ring.setAttribute("transform", "rotate(0,150,150)")

            }
            rows.map((data, index) => {
                var colActive = document.getElementById("col-nr-" + index)

                if (colActive != null) {
                    colActive = colActive.firstChild.firstChild
                    const offset = Math.round(Math.random() * 10)
                    const dash = Math.round(Math.random() * 10)
                    const space = Math.round(Math.random() * 10)
                    if (Math.random() > 0.95) {
                        const colorVal = Math.round(Math.random() * 255)

                        colActive.setAttribute("stroke", rgbToHex(colorVal, colorVal, colorVal));

                        colActive.setAttribute("stroke-dasharray", dash + " " + space);
                    } else {
                        colActive.setAttribute("stroke-dashoffset", offset.toString());
                    }
                }
            })


        })


        //console.log(rl)

        body.onscroll = () => {


            const pos = Math.floor(window.scrollY);
            console.log(pos, '----', this.state.posState)// if(tweenObj.cancel(false)) tweenObj.cancel(false);
            this.state.posStart = pos;



            if (pos == this.state.posStart) {
                if (tweenObj != null && tweenObj.isPlaying) tweenObj.cancel(false)
                //console.log(pos, '+++++ pos')
                tweenObj = tween({
                    from: {posState: this.state.posStart},
                    to: {posState: pos},
                    duration: 10,
                    easing: 'linear',
                    render: state => {
                        this.state.posState = state.posState;
                        this.animate()
                        //console.log(state.posState, 'tween')
                    },
                }).then(() => {
                    //  xthis.state.posState = pos
                    //this.animate()
                })
                //tweenObj.cancel(true)


                //this.state.posState = this.state.pos;
                const break1 = 300;


                // console.log(expand);
            }

            //console.log(window.scrollY);
        };

    }

    animate() {
        for (let i = 0; i < timeline.length; i++) {

                timeline[i].do(this.state.posState);


            for (let key in timeline[i].params) {
                //console.log(key + "KEY");

                switch (key) {
                    case "tagShift" :
                        tu.setAttribute("transform", "translate(" + timeline[i].params["tagShift"] + "," + -timeline[i].params["tagShift"] + ")")
                        td.setAttribute("transform", "translate(" + -timeline[i].params["tagShift"] + "," + timeline[i].params["tagShift"] + ")")
                        break
                    case "ringStatusDashArray" :
                        rrp.setAttribute("stroke-dasharray", timeline[i].params["ringStatusDashArray"])
                        rrp.setAttribute("stroke-dashoffset", timeline[i].params["ringStatusDashOffset"])
                        rlp.setAttribute("stroke-dasharray", timeline[i].params["ringStatusDashArray"])
                        rlp.setAttribute("stroke-dashoffset", timeline[i].params["ringStatusDashOffset"])
                        break;
                    case "ringStatusRotate":
                        ring.setAttribute("transform", "rotate(" + -timeline[i].params["ringStatusRotate"] + ",150,150)")
                        break
                    default:

                        break;
                }
            }

        }
        text.style.top = ((-this.state.posState) + "px").toString()
        textl.style.top = ((-this.state.posState) + "px").toString()
        //console.log(this.state.posState + ' ---- posState');


    }


    render() {

        return (
            <div className="tp-main-container">

                <div className="logo-fix">
                    <div className="tp-grid-fix">{rows}</div>
                    <div className="tp-story-container">
                        <div className="tp-story-text-container tp-4">
                            <div className="tp-story-text" id="tp-story-text">

                                <p>UX is the joy to achieve <div className="tp-highlight">goals</div> by
                                    intuition. Get feedback and always it is taken care
                                    about all your actual needs by the interface you are using.
                                </p>
                                <p className="tp-black">So we design and develop services which expect universal
                                    views and offers simple understandable workflows for repeatable success.</p>
                                <p>We use any high develop technologies to to assembly. So we have for long last
                                    products. Technological and design research and constant evaluation is one
                                    of our main strength for offer state of the art solutions.</p>
                            </div>
                        </div>
                        <div className="tp-story tp-4">
                            <svg className="anim3" version="1.0" id="Logo" xmlns="http://www.w3.org/2000/svg"
                                 x="0px"
                                 y="0px"
                                 viewBox="-300 -300 900 900" cx="-300" cy="-300">
                                <g id="ring" cx="300">
                                    <g id="ring-right">
                                        <path id="ring-right-path"
                                              d="M199.4,26c-15.3-6.1-32-9.5-49.4-9.5C76.3,16.5,16.5,76.3,16.5,150c0,17.5,3.4,34.2,9.5,49.4"
                                              fill="transparent" stroke="#000" strokeWidth="28"/>
                                    </g>
                                    <g id="ring-left">
                                        <path id="ring-left-path"
                                              d="M100.6,274c15.3,6.1,32,9.5,49.4,9.5c73.7,0,133.5-59.8,133.5-133.5c0-17.5-3.4-34.2-9.5-49.4"
                                              fill="transparent" stroke="#000" strokeWidth="28"/>
                                    </g>
                                </g>
                                <g id="tag-up" transform="translate(30,-30)">
                                    <polyline
                                        points="182.4,196.7 182.4,116 101.7,116" fill="transparent"
                                        stroke="#000"
                                        strokeWidth="28"/>
                                </g>
                                <g id="tag-down" transform="translate(-30,30)">
                                    <polyline id="tag-down"
                                              points="115.6,102.8 115.6,183.5 196.3,183.5" fill="transparent"
                                              stroke="#000" strokeWidth="28"/>
                                </g>

                            </svg>
                        </div>
                        <div className="tp-4 xs-hide tp-story-text-container">
                            <div className="tp-story-text" id="tp-story-text-left">

                                <p className="tp-black">UX is the joy to achieve goals by intuition. Get
                                    feedback and always it is taken care
                                    about all
                                    your actual needs.</p>
                                <p>So we design and develop services which expect universal views and offers
                                    simple understandable workflows for repeatable success.</p>
                                <p className="tp-black">We use any high develop technologies to to assembly. So
                                    we have for long last products. Technological and design research and
                                    constant evaluation is one of our main strength for offer state of the are
                                    solutions.</p>
                                       <a href="mailto:tom.walcher@enabled.io" style={{color:"#fff", "z-index":8000,"pointer-events":"normal"}}>tom.walcher@enabled.io</a>
                            </div>
                        </div>
                     </div>

                </div>
                }

            </div>
        )
    }

}
